<template>
  <div class="Terminos">
    <v-container>
      <v-row class="card-poly">
        <v-col cols="12" md="12" lg="12">
          <div class="col-md-12 col-terms">
            <div class="terms-title">
              <h1 class="color-primary">
                <span>TÉRMINOS Y CONDICIONES</span> <br />GIVEAWAY OCHO TE LLEVA A TELAMAR
              </h1>
            </div>

            <div class="terms-sub-title">
              <p>El operador del SITIO WEB y el APP</p>
            </div>
            <div class="terms-details">
              <p class="depto-details">
                El SITIO WEB y el APP son operados por OCHO Corporation.
              </p>
            </div>
            <div class="terms-details">
              <p class="depto-details">
                Última actualización: 10 de febrero 2023
              </p>
            </div>

            <br />
            <p>
              Por medio del presente se dan a conocer los términos y condiciones
              generales aplicables para la dinámica que se llevará a cabo desde
              el 10 al 28 de febrero de 2023 por medio de redes sociales
              denominado "<b>GIVEAWAY OCHO TE LLEVA A TELAMAR</b>" con sedes en
              la ciudad de Tegucigalpa.
            </p>

            <p>
              Para participar en la Dinámica, se deberá cumplir con los
              siguientes requisitos:
            </p>
            <p>
              <ul>
                <li>*Ser mayor de edad (acreditar con una identificación oficial tal circunstancia)</li>
                <li>*Participar en la dinámica completa puesta en redes sociales.</li>
                <li>*Realizar compras iguales o mayores a L500 en ocho app.</li>
                <li>*Tener una cuenta activa en la plataforma/aplicación denominada Ocho Corporation.</li>
                <li>*El premio será válido en Tegucigalpa y San Pedro Sula.</li>
              </ul>
            </p>
            <p>Contar con la Tarjeta de Identidad al momento de la entrega del Incentivo 
                Atenerse a los presentes Términos y Condiciones.
              </p>
                <p>Premio a ganarse:
            Estadía para dos personas en Telamar Resort con servicio de paquete todo incluido.</p>
              <p>Se otorgará el premio de la siguiente manera:</p>

<p>Se realizará el sorteo el 28 de febrero del 2023, la entrega será coordinada para el día miércoles 1ero de marzo del presente año. </p>
<p>El premio no podrá ser canjeado por dinero en efectivo o cualquier otro producto distinto al mencionado en el punto anterior.</p>
<p>Tampoco podrá obtener el premio una persona que labore como empleado de Ocho Corporation o cualquiera de sus subsidiarias y/o filiales.
</p>
<p>El paquete será válido de viernes a domingo del 2023. Promoción vigente del 01 marzo al 31 mayo 2023, <b> (No aplica en Semana Santa del 01 al 08 abril, del 29 de abril al 02 de mayo, realizar 15 días antes la reservación por la disponibilidad)</b>
</p>
<p>Para el registro en el Hotel es obligatorio presentar la identidad para adultos y partida de nacimiento para menores, también pueden realizarlo con el pasaporte.</p>
<p>TARIFA DE NIÑOS: <br>
Niños 0-5 años no Pagan<br>
Niños 6-14 años pagan $59.00 por noche.<br>
A partir de 15 años pagan tarifa completa.
</p>
<p>Check in a las 3:00 p.m. y check out a la 1:00 p.m. <br>
Ingreso previo a las 3:00 p.m. se realizará un cobro de $ 30.00 por persona y por niño de 6 a 14 años, esto le dará derecho al almuerzo, snacks y open bar a partir de las 10:00 a.m.</p>

<p><b>HABITACIÓN:</b> 2 camas queen size, aire acondicionado, baño con agua fría y caliente, caja fuerte, Televisor, mini refrigerador, percoladora, balcón.</p>
<p><b>COMIDAS:</b> Desayuno, almuerzo y cena, alimentación aplica ingresando con la cena del día de llegada y finalizando con el almuerzo del día de salida
</p>
<p><b>BEBIDAS QUE INCLUYE EL PAQUETE:</b><br>
Bebidas Nacionales: desde las 10:00 a.m. hasta las 11:00 p.m.
<ul>
  <li>*Gaseosas y Jugos</li>
  <li>*Cervezas Nacionales: Salvavidas, Gallo, Barena, Imperial y Miller Lite</li>
  <li>*Cocteles: Margaritas, Daiquiris, Piña Colada.</li>
  <li>*Bebidas: Ron Tonic, Cuba Libre, Screwdriver, Vodka Tonic y Tequila</li>
</ul>
  </p>

  <p><b>HORARIOS</b> <br>Desayunos: 7:00 a.m - 10:00 a.m. <br>Almuerzo: 12:00 m.d. - 3:00 p.m. <br>Cena: 6:30 p.m - 9:00 p.m. <br>Snack 10:00 a.m - 5:00 p.m. <br>Bar abierto: 10:00 a.m - 11:00 p.m. </p>

<p><b>IMPORTANTE:</b>
Se permitirá un cupo limitado de huéspedes. <br>
No se brindará servicio de Day Pass y tampoco se permitirán visitas a huéspedes. <br>
Uso de mascarilla obligatorio en todas las instalaciones <br>
El ganador del premio deberá buscar su medio de transporte <br>
<br><br>La dinámica se llevará a cabo los días previamente mencionados y se localizará al ganador una vez se haya revisado que cumpliera todos los requisitos.
<br><br>El ganador del premio deberá estar consciente y de acuerdo con los términos y condiciones. Será responsabilidad entera de los mismos el uso, desligando a Ocho de responsabilidad alguna y teniendo la obligación de mantener indemne a Ocho Corporation ante cualquier procedimiento que se pudiera iniciar en contra de Ocho Corporation.
<br><br>El incumplimiento de alguno de los términos y condiciones o requisitos establecidos en la presente, hará que el usuario no pueda ser considerado como acreedor al premio y su lugar sea cedido a otro usuario inmediato anterior de acuerdo con lo establecido en los presentes términos y condiciones.
<br><br>El usuario reconoce y acepta que la información proporcionada y registrada en la plataforma Ocho Corporation, es veraz y fidedigna, haciéndose completamente responsable por su falsedad.
<br><br>Ocho Corporation se reserva el derecho a confirmar la veracidad de datos suministrados por el usuario. Ocho Corporation se reserva el derecho de cancelar la dinámica si detecta intención de fraude.
</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "",
  components: {},
  mounted: function () {
    window.scrollTo(0, 0);

    // gsap.to('.phones', {x: 500, duration: 3})
  },
};
</script>
<style  src="@/main.scss" lang="scss"></style>
<style lang="scss"  >
@import "@/_responsive.scss";
.Terminos {
  width: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url("../assets/banners-poly.jpg");
  background-size: contain;
  height: auto;
  position: relative;
  background-color: rgb(255, 255, 255);
}
.terms-title {
  font-family: "Poppis-bold";
  font-size: 30px;
}
.terms-sub-title {
  p {
    font-family: "poppins-bold";
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.card-poly {
  background-color: #fff;
  border-radius: 1em;
  box-shadow: #2c2c2c3d 0px 10px 30px;
  padding: 2em;
}
</style>